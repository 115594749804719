import image1 from "../assets/t-image1.jpg";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Sé que he elegido bien, el trato de los entrenadores y su atención es espectacular.",
    name: 'JUAN CARLOS HERNANDEZ',
    status : 'CLIENTE'
  },
  {
    image: image2,
    review: 'Con este gimnasio he notado progresos desde el primer día. Te ayudan muchísimo.',
    name: 'ALBERTO VALLS',
    status: 'CLIENTE'
  },
  {
    image : image3,
    review:' Tenemos todo lo que necesitas para que te sientas cómodo con nosotros. ¿A qué esperas?',
    name: 'FRANKLIN',
    status: "ENTRENADOR"
  }
];
